import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-visitacao-chamada',
  templateUrl: './visitacao-chamada.component.html',
  styleUrls: ['./visitacao-chamada.component.css']
})
export class VisitacaoChamadaComponent implements OnInit {

  private imagemChamada: string = 'assets/img/pessoas/ministra_jaqueline_01.jpg';
  private tituloChamada: string ='Visitação!';
  private textoChamada: string =  'Está com dúvida sobre as regras de visitação do solo Sagrado? ' +
                                  'Sem problemas! Clique no botão abaixo e descubra!';
  
    getImagemChamada(){
      return this.imagemChamada;
    }
  
   getTituloChamada(){
      return this.tituloChamada;
    }
  
    getTextoChamada(){
      return this.textoChamada;
    }

  constructor() { }

  ngOnInit() {
  }

}
