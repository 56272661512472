import { Component, OnInit } from '@angular/core';

import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { DocumentoGuias } from './material-apoio.model';
import { MaterialApoioDetalheService } from './material-apoio-detalhe.service';


@Component({
  selector: 'app-material-apoio-detalhe',
  templateUrl: './material-apoio-detalhe.component.html',
  styleUrls: ['./material-apoio-detalhe.component.css']
})
export class MaterialApoioDetalheComponent implements OnInit {
  contentArray = new Array(90).fill('');
  returnedArray: string[];

  documentos: DocumentoGuias[] = [];
  returnedDocumento: DocumentoGuias[];

  private imagemChamadaApoio: String = 'assets/img/pessoas/jader_01.jpg';
  private tituloChamada: String = 'Material de Apoio';

  getImagemChamadaApoio() {
    return this.imagemChamadaApoio;
  }

  getTituloChamada(){
    return this.tituloChamada;
  }

  constructor(private cadastroService : MaterialApoioDetalheService) { }

  ngOnInit() {
    //this.contentArray = this.contentArray.map((v: string, i: number) => `Content line ${i + 1}`);
    //this.returnedArray = this.documentos.map((v:DocumentoGuias, i: number )=> `Content line ${i + 1}`);
    //this.returnedArray = this.contentArray.slice(0, 10);
    
    const documentoObservable = this.cadastroService.getDocumento();
      documentoObservable.subscribe((documentoData: DocumentoGuias[]) => {
        this.documentos = documentoData;
        this.returnedDocumento = this.documentos.slice(0, 10);
      });

      
  }

  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    console.log(startItem);
    const endItem = event.page * event.itemsPerPage;
    console.log(endItem);
    this.returnedDocumento = this.documentos.slice(startItem, endItem);
    console.log(this.returnedDocumento.length);

  }

}
