import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calendario-chamada',
  templateUrl: './calendario-chamada.component.html',
  styleUrls: ['./calendario-chamada.component.css']
})
export class CalendarioChamadaComponent implements OnInit {

  private imagemChamada: string = 'assets/img/pessoas/jader_01.jpg';
  private tituloChamada: string ='Calendário!';
  private textoChamada: string =  'Acesse o nosso calendário e fique por dentro ' +
                                  'das nossas próximas dedicações!' +
                                  'Programe-se, contate o seu responsável e venha dedicar conosco!';

  getImagemChamada(){
    return this.imagemChamada;
  }

  getTituloChamada(){
    return this.tituloChamada;
  }

  getTextoChamada(){
    return this.textoChamada;
  }

  constructor() { }

  ngOnInit() {
  }

}
