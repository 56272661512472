import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-avisos',
  templateUrl: './avisos.component.html',
  styleUrls: ['./avisos.component.css']
})
export class AvisosComponent implements OnInit {

  /* Imagens dos avisos */
  private imagemDialog: string = 'assets/img/icones/dialog_01.png';
  private fotoMinLourdes: string = 'assets/img/pessoas/ministra_lourdes_01.jpg';
  private fotoSidnei: string ='assets/img/pessoas/sidnei_rodrigues_01.jpg';
  private fotoJader: string = 'assets/img/pessoas/jader_01.jpg';
  private fotoMinVera: string = 'assets/img/pessoas/ministra_vera_01.jpg';
  private fotoMinJaqueline: string = 'assets/img/pessoas/ministra_jaqueline_01.jpg';
  private fotoEdilson: string = 'assets/img/pessoas/edilson_01.jpg';
  private fotoGiovana: string ='assets/img/pessoas/giovana_lindote_01.jpg';
  
  getImagemDialog(){
     return this.imagemDialog;
  }

  getFotoSidnei(){
    return this.fotoSidnei;
  }

  getFotoJader(){
    return this.fotoJader;
  }

  getFotoMinLourdes(){
    return this.fotoMinLourdes;
  }

  getFotoMinVera(){
    return this.fotoMinVera;
  }

  getFotoEdilson(){
    return this.fotoEdilson;
  }

  getFotoMinJaqueline(){
    return this.fotoMinJaqueline;
  }

  getFotoGiovana(){
    return this.fotoGiovana;
  }
  
  constructor() { }

  ngOnInit() {
  }

}
