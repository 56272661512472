import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from  '@angular/common/http';

import { CarouselModule, CollapseModule, BsDropdownModule } from 'ngx-bootstrap';
import { PaginationModule } from 'ngx-bootstrap';
import { ModalModule } from 'ngx-bootstrap';
import { PopoverModule } from 'ngx-bootstrap';


import { library } from '@fortawesome/fontawesome-svg-core';

import { faFileDownload, faBars, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BarraNavegacaoComponent } from './barra-navegacao/barra-navegacao.component';
import { ApresentacaoComponent } from './apresentacao/apresentacao.component';
import { AvisosComponent } from './avisos/avisos.component';
import { CalendarioChamadaComponent } from './calendario-chamada/calendario-chamada.component';
import { EnsinamentosChamadaComponent } from './ensinamentos-chamada/ensinamentos-chamada.component';
import { MaterialApoioChamadaComponent } from './material-apoio-chamada/material-apoio-chamada.component';
import { VisitacaoChamadaComponent } from './visitacao-chamada/visitacao-chamada.component';
import { RelatorioChamadaComponent } from './relatorio-chamada/relatorio-chamada.component';
import { RelacaoDedicantesChamadaComponent } from './relacao-dedicantes-chamada/relacao-dedicantes-chamada.component';
import { ExperienciaFeChamadaComponent } from './experiencia-fe-chamada/experiencia-fe-chamada.component';
import { PerfilMonitorChamadaComponent } from './perfil-monitor-chamada/perfil-monitor-chamada.component';
import { SsgEvolucaoChamadaComponent } from './ssg-evolucao-chamada/ssg-evolucao-chamada.component';
import { SsgHistoricoDetalheComponent } from './ssg-historico-detalhe/ssg-historico-detalhe.component';
import { HomeComponent } from './home/home.component';
import { EnsinamentosDetalhesComponent } from './ensinamentos-detalhes/ensinamentos-detalhes.component';
import { LoginComponent } from './login/login.component';
import { LoginModule } from './login/login.module';
import { FormsModule } from '@angular/forms';
import { AuthService } from './login/auth.service';
import { AuthGuard } from './login/auth.guard';
import { MaterialApoioDetalheComponent } from './material-apoio-detalhe/material-apoio-detalhe.component';
import { CalendarioDetalhesComponent } from './calendario-detalhes/calendario-detalhes.component';


library.add(faFileDownload);
library.add(faBars);
library.add(faCalendarAlt);

@NgModule({
  declarations: [
    AppComponent,
    BarraNavegacaoComponent,
    ApresentacaoComponent,
    AvisosComponent,
    CalendarioChamadaComponent,
    EnsinamentosChamadaComponent,
    MaterialApoioChamadaComponent,
    VisitacaoChamadaComponent,
    RelatorioChamadaComponent,
    RelacaoDedicantesChamadaComponent,
    ExperienciaFeChamadaComponent,
    PerfilMonitorChamadaComponent,
    SsgEvolucaoChamadaComponent,
    SsgHistoricoDetalheComponent,
    HomeComponent,
    EnsinamentosDetalhesComponent,
    MaterialApoioDetalheComponent,
    CalendarioDetalhesComponent,
    //LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LoginModule,
    FormsModule,
    CarouselModule.forRoot(),
    PaginationModule.forRoot(),
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    FontAwesomeModule,
    HttpClientModule
  ],
  providers: [
    AuthService,
    AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
