import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-material-apoio-chamada',
  templateUrl: './material-apoio-chamada.component.html',
  styleUrls: ['./material-apoio-chamada.component.css']
})
export class MaterialApoioChamadaComponent implements OnInit {

  private imagemChamada: string = 'assets/img/pessoas/ministra_vera_01.jpg';
  private tituloChamada: string ='Material Apoio';
  private textoChamada: string =  'Acesse e baixe o Manual dos Guias do Solo Sagrado. ' +
                                  'Também, nesta seção estão disponíveis os formulários para dedicação, ' +
                                  'modelos para confeccção de crachás e palestras.';
  
    getImagemChamada(){
      return this.imagemChamada;
    }
  
   getTituloChamada(){
      return this.tituloChamada;
    }
  
    getTextoChamada(){
      return this.textoChamada;
    }

  constructor() { }

  ngOnInit() {
  }

}
