import { Injectable } from '@angular/core';

import { HistoricoSSG } from './ssg-historico-detalhe.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SsgHistoricoService {
  historico: HistoricoSSG[] = [{
    id: 1,
    ano: '1974',
    titulo: 'Aquisição do Terreno',
    subtitulo: '',
    descricao: 'A IMMB adquire um terreno de 327.500 m2 (13,53 alqueires) ' +
                'às margens da represa de Guarapiranga, no bairro do Aragoa, '+
                'São Paulo (SP), para construir a futura Sede Central do Brasil. '+
                'O terreno foi indicado à IMMB pelo artista plástico Manabu Mabe. '+
                'Assim, desde o início, o Solo Sagrado de Guarapiranga traz a marca da '+
                'arte e da internacionalidade. Na foto, o reverendo Tetsuo Watanabe (esq.) '+
                'inspeciona o local.',
    endereco: 'assets/img/fotos/ssg-historico-1974.jpg'
  },
  {
    id: 2,
    ano: '1977 à 1984',
    titulo: 'Desbravamento',
    subtitulo: '',
    descricao:  'Durante sete anos, os messiânicos brasileiros desbravaram o ' +
                'local para que fosse construído o altar provisório.',
    endereco: 'assets/img/fotos/ssg-historico-1977.jpg'
  },
  {
    id: 3,
    ano: '1985',
    titulo: 'Autorização Sandai-Samavamento',
    subtitulo: '',
    descricao:  'Os membros do Conselho Deliberativo da IMMB, em visita ' +
                'oficial à Terceira Líder Espiritual, solicitam em nome dos '+
                'messiânicos brasileiros autorização para construir, em Guarapiranga, ' +
                'o “Soreisha” (Santuário dos Ancestrais). Poucos meses após a visita, ' +
                'o Rev.Tetsuo Watanabe, presidente da IMMB, retornou ao Brasil trazendo a ' +
                'resposta positiva da Líder Espiritual.',
    endereco: 'assets/img/fotos/ssg-historico-1985.jpg'
  },
  {
    id: 4,
    ano: '1985',
    titulo: 'Altar Provisório',
    subtitulo: '',
    descricao:  'Agosto de 1985 - Altar provisório, projeto inspirado no Santuário Komyo ' +
                '(Solo Sagrado de Hakone – Japão).',
    endereco: 'assets/img/fotos/ssg-historico-1985-08.jpg'
  },
  {
    id: 5,
    ano: '1985',
    titulo: '50 anos da fundação da IMM | 30 anos de difusão da fé messiânica no Brasil',
    subtitulo: 'Preparação',
    descricao:  'Para as comemorações, que tiveram como ponto alto a visita missionária ' +
                'da Terceira Líder Espiritual da Igreja, foram necessários vários meses ' +
                'de cuidadosos preparativos.',
    endereco: 'assets/img/fotos/ssg-historico-1985-09a.jpg'
  },
  {
    id: 6,
    ano: '1985',
    titulo: 'Visita Missionária | Sandai-Sama (1/4)',
    subtitulo: 'Culto Especial de Pedido de Prece',
    descricao:  '15 de setembro de 1985 - ' +
                'Cerca de 52 mil membros brasileiros e 307 da Argentina, Chile, ' +
                'Paraguai, Uruguai e Peru participaram do Culto Especial de Pedido de Prece, ' +
                'oficiado pela Terceira Líder Espiritual em Guarapiranga. O evento teve o '+
                'apoio de 1.644 dedicantes.',
      endereco: 'assets/img/fotos/ssg-historico-1985-09c.jpg'
  },
  {
    id: 7,
    ano: '1985',
    titulo: 'Visita Missionária | Sandai-Sama (2/4)',
    subtitulo: 'Terceira Líder Espiritual em 15.09.1985',
    descricao:  '“Dediquem na Obra Divina com objetivo de salvar os brasileiros pelas '+
                'mãos dos brasileiros. Sinto que o tempo está maduro para ser construído, '+
                'aqui, o Protótipo do Paraíso Terrestre, com o Santuário Divino e o Templo '+
                'dos Ancestrais. Os Conselheiros também já manifestaram essa necessidade e ' +
                'estou muito feliz pela elevação da fé dos senhores. Estou orando para que, '+
                'voltados para esse sonho que se tornou próximo, cada um dos senhores, '+
                'devotando sua sinceridade ao servir que lhes foi conferido, reúna forças '+
                'para concretizar esta construção”.',
      endereco: 'assets/img/fotos/ssg-historico-1985-09d.jpg'
  },
  {
    id: 8,
    ano: '1985',
    titulo: 'Visita Missionária | Sandai-Sama (3/4)',
    subtitulo: 'Nova Etapa na Obra Divina',
    descricao:  '50 mil corações batendo no mesmo ritmo; 50 mil vozes orando juntas ' +
                'e 100 mil mãos aplaudindo com o mesmo fervor e entusiasmo. Ao deixar o local que '+
                'com tanto amor e devoção foi preparado para esta memorável reunião, '+
                'todos levaram para casa a certeza de terem assistido ao nascimento de uma nova '+
                'etapa na Obra Divina no nosso país – uma nova etapa da Obra Divina no mundo.',
      endereco: 'assets/img/fotos/ssg-historico-1985-09e.jpg'
  },
  {
    id: 9,
    ano: '1985',
    titulo: 'Visita Missionária | Sandai-Sama (4/4)',
    subtitulo: 'Fogo, Água e Terra',
    descricao:  'Sandai-Sama e Reverendíssimo Watanabe com missionários no SSG. '+
                'União do Fogo (ilha ao fundo), Água (Represa de Guarapiranga) e Terra (SSG).',
      endereco: 'assets/img/fotos/ssg-historico-1985-09f.jpg'
  },
  {
    id: 10,
    ano: '1987',
    titulo: 'Contrução de Alojamento',
    subtitulo: 'Agosto',
    descricao:  'Para possibilitar aos ministros, missionários e membros um ' +
                'programa de aprimoramento, e objetivando a renovação da emoção do '+
                'Culto oficiado pela Terceira Líder Espiritual da Igreja, foi construído '+
                'um alojamento com acomodações para 42 pessoas.',
      endereco: 'assets/img/fotos/ssg-historico-1987a.jpg'
  },
  {
    id: 11,
    ano: '1987',
    titulo: 'Comissão',
    subtitulo: '',
    descricao:  'Comissão composta pela Direção da IMMB, engenheiros e arquitetos.',
    endereco: 'assets/img/fotos/ssg-historico-1987b.jpg'
  },
  {
    id: 12,
    ano: '1987',
    titulo: 'Nova Contribuição',
    subtitulo: 'Contribuição do Arquiteto Sylvio Sawaya',
    descricao:  'Um integrante da Comissão de Construção levou ao conhecimento do '+
                'arquiteto Sylvio Sawaya, professor de Arquitetura da Universidade de '+
                'São Paulo – USP, nosso objetivo de construir um protótipo do “Paraíso na Terra”. '+
                'Entusiasmado com o desafio que o tema apresentava, ele propôs incorporá-lo ao '+
                'curso de pós-graduação do qual era coordenador. ' +
                'Como resultado foram apresentados 19 trabalhos, que foram expostos no campus da '+
                'Universidade, na Sede Central da IMMB e no Solo Sagrado de Atami, Japão. '+
                'Foram desenvolvidos quatro estudos e, em 1988, foi escolhido o trabalho de '+
                'Sylvio Sawaya.',
    endereco: 'assets/img/fotos/ssg-historico-1987c.jpg'
  },
  {
    id: 13,
    ano: '1989',
    titulo: 'Nova Infraestrutura',
    subtitulo: 'Maio de 1989',
    descricao:  'A capacidade de alojamento precisava ser ampliada e o Altar provisório, '+
                'desativado. Contudo, foram tomadas providências para que pudéssemos reverenciar '+
                'Deus e Meishu-Sama. '+
                'Deu-se início à montagem de cinco unidades pré-fabricadas, com conforto e '+
                'funcionalidade para a realização das atividades que ali seriam desenvolvidas: ' +
                'a) Altar; b) Alojamento (4 casas com capacidade de 20 pessoas/unidade). ' +
                'Somando-se ao alojamento anterior, a capacidade total de acomodações '+
                'passou a ser de 122 pessoas.',
      endereco: 'assets/img/fotos/ssg-historico-1989a.jpg'
  },
  {
    id: 14,
    ano: '1989',
    titulo: 'Pedra Fundamental (1/3)',
    subtitulo: 'Preparação para o Lançamento da Pedra Fundamental',
    descricao:  'A preparação para o Culto de Lançamento da Pedra Fundamental do Protótipo '+
                'do Paraíso durou dois meses. Cerca de 1.200 pessoas dedicaram-se à preparação '+
                'de toda área. Às 9 horas de 17 de setembro, tudo estava pronto e funcionando '+
                'com perfeição quando a comitiva do presidente Yassushi Matsumoto chegou ao local.',
      endereco: 'assets/img/fotos/ssg-historico-1989b.jpg'
  },
  {
    id: 15,
    ano: '1989',
    titulo: 'Pedra Fundamental (2/3)',
    subtitulo: '17 de Setembro: Cerimônia e Culto',
    descricao:  'O eventou constou de 2 momentos: a) Cerimônia de Consagração do Terreno; ' +
                'b) Culto de Lançamento da Pedra Fundamental. Ambos momentos foram oficiados ' +
                'pelo presidente da IMM, reverendo Yassushi Matsumoto.' +
                'O presidente da IMMB, reverendo Tetsuo Watanabe, os dirigentes das áreas ' +
                'de difusão e representantes dos membros assentaram os tijolos que caracterizavam ' +
                'o lançamento da Pedra Fundamental da construção do Protótipo do Paraíso Terrestre.',
      endereco: 'assets/img/fotos/ssg-historico-1989c.jpg'
  },
  {
    id: 16,
    ano: '1989',
    titulo: 'Pedra Fundamental (3/3)',
    subtitulo: '12 mil pessoas',
    descricao:  'O evento aconteceu num clima de muita emoção, com a participação de 12 mil' +
                'pessoas. Foi encerrado com alegria e descontração, numa perfeita comunhão de ' +
                'sentimentos entre Brasil e Japão.',
    endereco: 'assets/img/fotos/ssg-historico-1989d.jpg'
  },
  {
    id: 17,
    ano: '0000',
    titulo: 'Ante-Projeto do Solo Sagrado',
    subtitulo: 'A Torre do Templo',
    descricao:  'Para o arquiteto Sylvio Sawaya, a torre do Templo tinha a função de promover '+
                'equilíbrio através de um elemento vertical, uma vez que, pelas características, ' +
                'o projeto tinha um aspecto predominantemente horizontal. ' +
                'Esse projeto foi apresentado à apreciação da diretoria do Solo Sagrado do Japão. ' +
                'O Rev. Hideo Sakakibara, que foi o responsável pela entronização da Imagem de Deus ' +
                'nos Solos Sagrados de Atami, Hakone e Kyoto, foi a primeira pessoa a ver o projeto. ' +
                'Ele considerou a torre o elemento mais significativo, e sugeriu que ela estivesse na ' +
                'direção da Imagem, porque essa torre tinha o significado do local de assentamento do ' +
                'Espírito Divino (“Amatsu-Kanagui”). ' +
                'Foi a partir das considerações do Rev. Sakakibara que a torre do Templo do ' +
                'Solo Sagrado, com 71 metros de altura conquistou definitivamente seu lugar no projeto.',
      endereco: 'assets/img/fotos/ssg-historico-ante-projeto.jpg'
  },
  {
    id: 18,
    ano: '1990',
    titulo: 'Sonho do Rev. Tetsuo Watanabe',
    subtitulo: 'Abril de 1990',
    descricao:  '“No meu sonho, eu estava num templo, fazendo oração. Havia uma torre ' +
                'bem alta e, em volta, pilares imponentes que eram ligadas, na parte superior, ' +
                'por um anel. Eu me senti realmente em sintonia com a natureza. O teto do templo ' +
                'era o céu; as paredes, a beleza natural do lugar. Havia também uma grande escada, ' +
                'que conduzia ao interior do templo. ' +
                'Quando acordei, fiz um desenho. Mostrei-o para a Comissão de Construção e todos ' +
                'ficaram boquiabertos. Um dos componentes da Comissão saiu da sala onde estávamos ' +
                'reunidos e retornou com uma foto do ‘Stonehenge’, considerada o mais antigo santuário ' +
                'do mundo ocidental. A semelhança era fantástica. Com a concordância de todos, começamos ' +
                'a reformular os planos e chegamos à configuração atual do Templo. ' +
                'Hoje, sinto que um grande sonho – de Meishu-Sama, da Líder Espiritual, meu e de ' +
                'todos os messiânicos brasileiros – está se tornando uma linda realidade. ' +
                'Isso fortalece minha convicção de que Meishu-Sama está, realmente, querendo ' +
                'utilizar todos os brasileiros – salvar a humanidade.” ' +
                '[Rev. Tetsuo Watanabe]',
    endereco: 'assets/img/fotos/ssg-historico'
  },
  {
    id: 19,
    ano: '1991',
    titulo: 'Início das Dedicações',
    subtitulo: 'Janeiro de 1991',
    descricao:  'Início da dedicação dos membros para implantação do projeto atual, ' +
                'com o reflorestamento da orla da represa.',
    endereco: 'assets/img/fotos/ssg-historico-1991a.jpg'
  },
  {
    id: 20,
    ano: '1991',
    titulo: 'Início da Construção do Templo',
    subtitulo: 'Maio de 1991',
    descricao:  'Início da Construção do Templo',
      endereco: 'assets/img/fotos/ssg-historico-1991b.jpg'
  },
  {
    id: 21,
    ano: '1991',
    titulo: 'Entronização no Altar Provisório',
    subtitulo: '17 de Junho de 1991',
    descricao:  'As imagens de Deus e Meishu-Sama são entronizadas no segundo Altar provisório.',
    endereco: 'assets/img/fotos/ssg-historico-1991c.jpg'
  },
  {
    id: 0,
    ano: '0000',
    titulo: '',
    subtitulo: '',
    descricao:  '',
      endereco: 'assets/img/fotos/ssg-historico'
  },
  {
    id: 0,
    ano: '0000',
    titulo: '',
    subtitulo: '',
    descricao:  '',
      endereco: 'assets/img/fotos/ssg-historico'
  },
  {
    id: 0,
    ano: '0000',
    titulo: '',
    subtitulo: '',
    descricao:  '',
      endereco: 'assets/img/fotos/ssg-historico'
  },
  {
    id: 0,
    ano: '0000',
    titulo: '',
    subtitulo: '',
    descricao:  '',
      endereco: 'assets/img/fotos/ssg-historico'
  },
  {
    id: 0,
    ano: '0000',
    titulo: '',
    subtitulo: '',
    descricao:  '',
      endereco: 'assets/img/fotos/ssg-historico'
  },
  {
    id: 0,
    ano: '0000',
    titulo: '',
    subtitulo: '',
    descricao:  '',
      endereco: 'assets/img/fotos/ssg-historico'
  },
  {
    id: 0,
    ano: '0000',
    titulo: '',
    subtitulo: '',
    descricao:  '',
      endereco: 'assets/img/fotos/ssg-historico'
  },
  {
    id: 0,
    ano: '0000',
    titulo: '',
    subtitulo: '',
    descricao:  '',
      endereco: 'assets/img/fotos/ssg-historico'
  }

]

  constructor() { }

  public getHistorico(): any {
    const historicoObservable = new Observable(observer => {
           setTimeout(() => {
               observer.next(this.historico);
           }, 1000);
    });

    return historicoObservable;
  }
}
