import { Component, OnInit } from '@angular/core';

import { HistoricoSSG } from './ssg-historico-detalhe.model';
import { SsgHistoricoService } from './ssg-historico.service';

@Component({
  selector: 'app-ssg-historico-detalhe',
  templateUrl: './ssg-historico-detalhe.component.html',
  styleUrls: ['./ssg-historico-detalhe.component.css']
})
export class SsgHistoricoDetalheComponent implements OnInit {

  historicos: HistoricoSSG[] = [];

  constructor(private historicoService : SsgHistoricoService) { }

  ngOnInit() {
    const historicoObservable = this.historicoService.getHistorico();
          historicoObservable.subscribe((historicoData: HistoricoSSG[]) => {
            this.historicos = historicoData;
        });

  }

}
