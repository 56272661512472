import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ensinamentos-chamada',
  templateUrl: './ensinamentos-chamada.component.html',
  styleUrls: ['./ensinamentos-chamada.component.css']
})
export class EnsinamentosChamadaComponent implements OnInit {

private imagemChamada: string = 'assets/img/pessoas/ministra_lourdes_01.jpg';
private tituloChamada: string ='Ensinamentos';
private textoChamada: string =  'Confira os 10 Principais Ensinamentos ' +
                                'que recomendamos para todos os guias. Eles ' +
                                'servem de inspiração para aprimorarmos cada vez mais o nosso servir.';

  getImagemChamada(){
    return this.imagemChamada;
  }

 getTituloChamada(){
    return this.tituloChamada;
  }

  getTextoChamada(){
    return this.textoChamada;
  }
  
  constructor() { }

  ngOnInit() {
  }

}
