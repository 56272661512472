import { Component, OnInit } from '@angular/core';
import { Programacao, Regiao } from './calendario.model';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';

import { CalendarioDetalhesService } from './calendario-detalhes.service';
import { HttpClient } from  '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';



@Component({
  selector: 'app-calendario-detalhes',
  templateUrl: './calendario-detalhes.component.html',
  styleUrls: ['./calendario-detalhes.component.css']
})
export class CalendarioDetalhesComponent implements OnInit {

  programacoes: Programacao[] = [];
  returnedProgramacoes: Programacao[] = [];
  regioes: Regiao[] = [];
  tituloChamada: string = "Calendário Dedicação";

  
  getTituloChamada(){
    return this.tituloChamada;
  }

  //constructor(private programacaoService: CalendarioDetalhesService) { }
  constructor(private http: HttpClient) { }

  carregarProgramacao(){

    //const headers = new HttpHeaders()
    //        .set('Access-Control-Allow-Origin', 'http://localhost:8080/programacao');

    this.http.get(`http://localhost:8080/programacao`)
    .subscribe((dados: Programacao[]) => {
        console.log(dados);
        this.programacoes = dados;
        this.returnedProgramacoes = this.programacoes.slice(0,10);
      }  );
  }

  carregarRegioes(){

    this.http.get(`http://localhost:8080/regiao`)
    .subscribe((jsonDados: Regiao[]) => {
      console.log(jsonDados);
      this.regioes = jsonDados;
    });
  }

  onEditClick(regiaoSelecionada: any){
    console.log('Valor Selecionado', regiaoSelecionada);
    this.http.get(`http://localhost:8080/regiao/${regiaoSelecionada}`)
    .subscribe((jsonDados: Regiao[]) => {
      console.log(jsonDados);

    });
  }

  carregarProgramacaoRegiao(regiaoSelecionada: any){
    console.log('Valor Selecionado: ', regiaoSelecionada);
    this.http.get(`http://localhost:8080/programacao/${regiaoSelecionada}`)
    .subscribe((jsonDados: Programacao[]) => {
      console.log(jsonDados);
      this.programacoes = jsonDados;
      this.returnedProgramacoes = this.programacoes.slice(0,10);
    });
  }

parseJsonDate(jsonDateString){
    //return new Date(parseInt(jsonDateString.replace('-', '')));
   // return new Date(jsonDateString);
   //var dtStr = JSON.parse(jsonDateString);
   var dt = new Date(jsonDateString);
    return dt;
}

formataDDMMYYYY(jsonDataRecebida: string){
  
  let dia : string = jsonDataRecebida.split("-")[2];
  let mes : string = jsonDataRecebida.split("-")[1];
  let ano: string = jsonDataRecebida.split("-")[0];

  return dia + '/' +  mes + '/' + ano;
  
}

formataMes(jsonDataRecebida: string){

  let mes = (parseInt(jsonDataRecebida.split("-")[1]) -1) ;

  let meses : any[] = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro"
  ];

  return meses [mes];

}

pageChanged(event: PageChangedEvent): void {
  const startItem = (event.page - 1) * event.itemsPerPage;
  console.log(startItem);
  const endItem = event.page * event.itemsPerPage;
  console.log(endItem);
  this.returnedProgramacoes = this.programacoes.slice(startItem, endItem);
  console.log(this.returnedProgramacoes.length);

}


  ngOnInit() {

    //const programacaoObservable = this.programacaoService.getProgramacao();
    //   programacaoObservable.subscribe((programacaoData: Programacao[]) => {
    //    this.programacoes = programacaoData;
    //  });

    this.carregarProgramacao();
    this.carregarRegioes();
  }

}
