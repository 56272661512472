import { Injectable } from '@angular/core';
import { DocumentoGuias } from './material-apoio.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MaterialApoioDetalheService {
  documento: DocumentoGuias[] = [{
    id: 1,
    documento: 'Manual dos Monitores',
    descricao: 'O nosso manual para apoiar nas dedicações',
    endereco: 'assets/documentos/001-001-ManualDosMonitoresDeInformacao.pdf',
    publicacao: '14/05/2019'
  },
  {
    id: 2,
    documento: 'Escala de Monitores 2019',
    descricao: 'As datas das nossas dedicações em 2019',
    endereco: 'assets/documentos/001-002-EscalaDosMonitoresDeInformacao2019.pdf',
    publicacao: '14/05/2019'  
  },
  {
    id: 3,
    documento: 'Aspectos Ambientais',
    descricao: 'Conheça os aspectos ambientais do SSG',
    endereco: 'assets/documentos/001-003-SSGAspectosAmbientais.ppt',
    publicacao: '18/06/2019'  
  },
  {
    id: 4,
    documento: 'Processo Evolutivo do SSG',
    descricao: 'Conheça o histórico do Solo Sagrado de Guarapiranga',
    endereco: 'assets/documentos/001-004-ProcessoEvolutivoDoSSG.pptx',
    publicacao: '14/05/2019'  
  },
  {
    id: 5,
    documento: 'Crachá',
    descricao: 'Arquivo modelo para confeccção dos crachás dos nossos monitores',
    endereco: 'assets/documentos/001-005-ModeloCrachaGuias.pdf',
    publicacao: '18/06/2019'  
  },
  {
    id: 6,
    documento: 'Formulário I - Dedicação por Período',
    descricao: 'Formulário para dedicação por período no SSG',
    endereco: 'assets/documentos/001-006-Formulario-I DedicacaoDePeriodoNoSoloSagradoDeGuarapiranga.pdf',
    publicacao: '18/06/2019'
  },
  {
    id: 7,
    documento: 'Formuário II - Dedicação Frequente e Monitoria SSG',
    descricao: 'Formulário de autorização para dedicar como monitor no SSG',
    endereco: 'assets/documentos/001-007-Formulario-II-DedicacaoFrequenteEDeMonitoriaNoSSG.pdf',
    publicacao: '18/06/2019'  
  },
  {
    id: 8,
    documento: 'Formulário IV - Ensaios Fotográficos',
    descricao: 'Formulário para realização de enasios fotográficos',
    endereco: 'assets/documentos/001-008-Formulario-IV-EnsaiosFotograficos-Normas.pdf',
    publicacao: '18/06/2019'  
  },
  {
    id: 9,
    documento: 'Formulário V - Solicitação de Visita ao SSG',
    descricao: 'Formulário para visitantes',
    endereco: 'assets/documentos/001-009-Formulario-V-SolicitacaoDeVisitaAoSoloSagrado.pdf',
    publicacao: '14/05/2019'  
  },
  {
    id: 10,
    documento: 'Ficha de Cadastro de Monitor de Informação',
    descricao: 'Formulário de cadastro e recadastro de monitores',
    endereco: 'assets/documentos/001-010-FichaDeCadastroDeGuia.xlsx',
    publicacao: '18/06/2019'  
  },
  {
    id: 11,
    documento: 'Informações Médicas',
    descricao: 'Ficha para preenchimento da situação de saúde do monitor',
    endereco: 'assets/documentos/001-011-InformacoesMedicas.xlsx',
    publicacao: '18/06/2019'  
  },
  {
    id: 12,
    documento: 'Carta de Recomendação',
    descricao: 'Carta de Recomendação do Ministro da Unidade do Monitor',
    endereco: 'assets/documentos/001-012-CartaDeRecomendacaoParaDedicacaoDeGuiaSSG2018.doc',
    publicacao: '18/06/2019'  
  },
  {
    id: 13,
    documento: 'Kit para novos dedicantes',
    descricao: 'Kit contendo todos os arquivos necessários para cadastro de novos dedicantes',
    endereco: 'assets/documentos/001-013-KitParaNovosDedicantesSSG.zip',
    publicacao: '18/06/2019'  
  },
  {
    id: 14,
    documento: 'Relatório de Dedicação',
    descricao: 'Relatório para preenchimento após dedicação do monitor',
    endereco: 'assets/documentos/001-014-RelatorioDaDedicacao.doc',
    publicacao: '18/06/2019'  
  },
  {
    id: 15,
    documento: 'Lista de Ramais do SSG',
    descricao: 'Lista de Remais do Solo Sagrado de Guarapiranga',
    endereco: 'assets/documentos/001-015-ListaRamaisSoloSagrado.pdf',
    publicacao: '18/06/2019'  
  },
  {
    id: 16,
    documento: 'Cartazes de Formação',
    descricao: 'Cartazes para a formação de Guias de todas as regiões',
    endereco: 'assets/documentos/001-016-Cartaz Curso de Formacao.zip',
    publicacao: '04/07/2019'  
  },


]

  constructor() { }

  public getDocumento(): any {
    const documentoObservable = new Observable(observer => {
           setTimeout(() => {
               observer.next(this.documento);
           }, 1000);
    });

    return documentoObservable;
  }
}
