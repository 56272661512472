import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { SsgHistoricoDetalheComponent } from './ssg-historico-detalhe/ssg-historico-detalhe.component';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { HomeComponent } from './home/home.component';
import { EnsinamentosDetalhesComponent } from './ensinamentos-detalhes/ensinamentos-detalhes.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './login/auth.guard';
import { MaterialApoioDetalheComponent } from './material-apoio-detalhe/material-apoio-detalhe.component';
import { CalendarioDetalhesComponent } from './calendario-detalhes/calendario-detalhes.component';

const routes: Routes = [
  { path: '', component: HomeComponent,  canActivate: [AuthGuard] },
  { path: 'historico-ssg', component: SsgHistoricoDetalheComponent, canActivate: [AuthGuard]},
  { path: 'ensinamentos', component: EnsinamentosDetalhesComponent, canActivate: [AuthGuard]},
  { path: 'material-apoio', component: MaterialApoioDetalheComponent, canActivate: [AuthGuard]},
  { path: 'calendario',component: CalendarioDetalhesComponent, canActivate: [AuthGuard]},
  { path: 'login', component: LoginComponent}
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes);

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
