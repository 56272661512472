import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { Usuario } from './usuario';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private usuarioAutenticado: boolean = false;

  private loggedIn = new BehaviorSubject<boolean>(false); // {1}

  get isLoggedIn() {
    return this.loggedIn.asObservable(); // {2}
  }

  login(user: Usuario){
    if (user.nome === 'monitores@gmail.com' && user.senha === '2019guia' ) { // {3}
      this.loggedIn.next(true);
      this.router.navigate(['/']);
    }
  }

  logout() {                            // {4}
    this.loggedIn.next(false);
    this.router.navigate(['/login']);
  }

  //mostrarMenuEmitter = new EventEmitter();

  constructor(private router: Router) { }

  fazerLogin(usuario: Usuario){
    if (usuario.nome === 'monitores@gmail.com' &&
        usuario.senha === '2019guia') {

          this.usuarioAutenticado = true;
          this.router.navigate(['/']);
          //this.mostrarMenuEmitter.emit(true);

          //console.log(this.mostrarMenuEmitter.subscribe());

        } else {
          this.usuarioAutenticado = false;
          //this.mostrarMenuEmitter.emit(false);
        }
  }
}
