import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-experiencia-fe-chamada',
  templateUrl: './experiencia-fe-chamada.component.html',
  styleUrls: ['./experiencia-fe-chamada.component.css']
})
export class ExperienciaFeChamadaComponent implements OnInit {

  private imagemChamada: string = 'assets/img/pessoas/edilson_01.jpg';
  private tituloChamada: string =' Experiência de Fé';
  private textoChamada: string =  'Acesse as experiências de fé de nossos monitores! Estas ' +
                                  'experiências podem nos inspirar, além de apresentar exemplos ' +
                                  ' de superação!';
  
    getImagemChamada(){
      return this.imagemChamada;
    }
  
   getTituloChamada(){
      return this.tituloChamada;
    }
  
    getTextoChamada(){
      return this.textoChamada;
    }

  constructor() { }

  ngOnInit() {
  }

}
