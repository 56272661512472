import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-relatorio-chamada',
  templateUrl: './relatorio-chamada.component.html',
  styleUrls: ['./relatorio-chamada.component.css']
})
export class RelatorioChamadaComponent implements OnInit {

  private imagemChamada: string = 'assets/img/pessoas/sidnei_rodrigues_01.jpg';
  private tituloChamada: string ='Relatório';
  private textoChamada: string =  'Envie o seu relatório de dedicação. Toda ' +
                                  'contribuição é bem vinda! Afinal, como representantes ' +
                                  'de Meishu-Sama, todos nós somos guardiões do Protótipo ' +
                                  'do Paraíso Terrestre!';
  
  getImagemChamada(){
    return this.imagemChamada;
  }
  
  getTituloChamada(){
    return this.tituloChamada;
  }
  
  getTextoChamada(){
    return this.textoChamada;
  }

  constructor() { }

  ngOnInit() {
  }

}
