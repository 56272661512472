import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-perfil-monitor-chamada',
  templateUrl: './perfil-monitor-chamada.component.html',
  styleUrls: ['./perfil-monitor-chamada.component.css']
})
export class PerfilMonitorChamadaComponent implements OnInit {

  private imagemChamada: string = 'assets/img/pessoas/rosangela_maciel_01.jpg';
  private tituloChamada: string ='Perfil do Guia';
  private textoChamada: string =  'Clique abaixo e conheça as recomendações para que um membro '+
                                  'possa ingressar como guia do SSG.'
  
    getImagemChamada(){
      return this.imagemChamada;
    }
  
   getTituloChamada(){
      return this.tituloChamada;
    }
  
    getTextoChamada(){
      return this.textoChamada;
    }

  constructor() { }

  ngOnInit() {
  }

}
