import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-apresentacao',
  templateUrl: './apresentacao.component.html',
  styleUrls: ['./apresentacao.component.css']
})
export class ApresentacaoComponent implements OnInit {
 
  /* Imagens do Portal */
  private imagemApresentacao: string = 'assets/img/fotos/SSG_01.jpg';

  getImagemApresentacao(){
    return this.imagemApresentacao;
  }

  constructor() { }

  ngOnInit() {
  }

}
