import { Injectable } from '@angular/core';

import { Ensinamento } from './ensinamento.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EnsinamentosDetalhesService {
 
  ensinamento: Ensinamento[] = [{
    id: 1,
    titulo: 'Bondade e Cortesia',
    resumo: '(...) Primeiro, devemos evitar as desavenças; depois, desenvolver a bondade; ' +
            'por fim, nos tornarmos mais corteses. (...)' +
            'Bondade, higiene e cortesia não custam dinheiro e são elementos essenciais, que ' +
            'atraem os turistas. (...)' +
            'A formação desse homem bondoso e cortês depende unicamente da Fé e constitui a '+
            'diretriz de nossa Igreja, que, nesse sentido, vem se desenvolvendo cada vez mais.',
    texto:  'Há um método que nos permite avaliar o nosso progresso na Fé e o nosso aprimoramento ' +
            'espiritual. Primeiro, devemos evitar as desavenças; depois, desenvolver a bondade; '+
            'por fim, nos tornarmos mais corteses. ' +
            'Se conhecermos alguém com tais atributos, veremos logo que é pessoa polida, que se '+
            'aprimorou e que possui o intrínseco valor da Fé. Essa pessoa será estimada e respeitada '+
            'por todos; suas atitudes valerão como uma silenciosa divulgação de Fé; servirá como '+
            'exemplo de Fé concretizada em atos. ' +
            'Mas o mundo atual mostra-nos, a todo instante, como é carente dessa bondade e cortesia. '+
            'Por toda parte, o ser humano vive a esmiuçar os defeitos alheios, odiando e recriminando ' +
            'a toda gente, salientando sempre os seus aspectos desagradáveis. Podemos afirmar que quase ' +
            'não existe cortesia no homem moderno. (...) ' +
            'Ser fiel às regras morais permite a formação de uma sociedade agradável, onde reina o '+
            'conforto. Se tal sociedade puder ser criada, o Paraíso será uma realidade para o homem. ' +
            'Bondade, higiene e cortesia não custam dinheiro e são elementos essenciais, que atraem os '+
            'turistas. ' +
            'A formação desse homem bondoso e cortês depende unicamente da Fé e constitui a diretriz '+
            'de nossa Igreja, que, nesse sentido, vem se desenvolvendo cada vez mais.',
    livro:  'Por Meishu-Sama - 25 de Outubro de 1950 - Alicerce do Paraíso, v. 4 (trechos)',
    paginas: ''
  },
  {
    id: 2,
    titulo: 'Pessoa Simpática',
    resumo: 'Talvez não exista nenhuma palavra que soe tão agradavelmente quanto “simpatia” '+
            '(...) Se alguém se tornasse simpático graças ao relacionamento com uma pessoa '+
            'simpática e isso fosse se propagando continuamente, é óbvio que a '+
            'sociedade se tornaria bastante agradável. (...) '+
            'A verdadeira simpatia aflora do interior (...) '+
            'a base da simpatia é o espírito de Amor ao Próximo. (...)',
    texto:  'Talvez não exista nenhuma palavra que soe tão agradavelmente quanto “simpatia”. '+
            'Pensando bem, a simpatia é muito mais importante do que imaginamos, pois tem '+
            'muita relação não só com o destino do indivíduo, mas também com a sociedade. '+
            'Se alguém se tornasse simpático graças ao relacionamento com uma pessoa simpática '+
            'e isso fosse se propagando continuamente, é óbvio que a sociedade se tornaria bastante '+
            'agradável. '+
            'Por conseguinte, diminuiriam os problemas, principalmente o conflito e o crime; '+
            'espiritualmente, criar-se-ia o Paraíso. Não existe meio melhor do que esse, pois '+
            'não requer dinheiro, não é trabalhoso e pode ser posto em prática imediatamente. '+
            'Falando, parece muito simples, mas todos sabem que, na realidade, não é tão fácil '+
            'assim, pois não basta que a simpatia seja apenas aparente. A verdadeira simpatia aflora '+
            'do interior; é indispensável, portanto, que a pessoa seja sincera de coração, o que depende '+
            'de cada um. Em suma, a base da simpatia é o espírito de Amor ao Próximo.'+
            'Vou contar um pouco da minha experiência a esse respeito. '+
            'É engraçado eu mesmo falar dessas coisas, mas desde pequeno, onde quer que eu '+
            'fosse, quase nunca era malquisto ou antipatizado. Pelo contrário, era respeitado e '+
            'amado na maioria das vezes. Então, pensando bem, concluí que tenho uma característica '+
            'que me parece ser o motivo disso: sempre deixo meus próprios interesses e minha própria '+
            'satisfação em segundo plano; procuro fazer, em primeiro lugar, aquilo que satisfaz aos '+
            'outros, aquilo que os deixa felizes. Ajo assim não por razões morais ou religiosas, mas '+
            'naturalmente. Talvez seja da minha própria natureza. Em outras palavras, é até uma '+
            'espécie de “hobby” para mim. Por essa razão, muitos dizem que tenho uma natureza '+
            'privilegiada, e é possível que tenha mesmo. '+
            'Depois que me tornei religioso, esse sentimento aumentou ainda mais. '+
            'Quando vejo uma pessoa sofrendo por doença, não consigo ficar tranquilo; '+
            'tenho vontade de curá-la a qualquer custo. Então, ministro-lhe Johrei, e ela fica '+
            'curada e feliz. Ao ver sua alegria, esta se reflete em mim e eu me sinto feliz também. '+
            'Por esse motivo, criei inúmeros problemas no passado e sofri muito. Mesmo quando achava '+
            'que nada poderia fazer por uma pessoa e que deveria parar de dar-lhe assistência, a '+
            'pedido insistente e até súplicas da própria pessoa e de sua família, eu cedia e continuava '+
            'indo visita-la, ainda que fosse longe. Gastava tempo e dinheiro, e, no final, o '+
            'resultado era ruim, desapontando os familiares do doente. Muitas vezes, cheguei '+
            'até a ser odiado. Toda vez que isso acontecia, eu me censurava, achando que deveria '+
            'tornar-me mais frio. '+
            'Como essa minha característica também foi de muita ajuda para a construção do '+
            'protótipo do Paraíso Terrestre e do Museu de Belas-Artes, creio que ela me tenha '+
            'sido atribuída por Deus. Quando vejo uma magnífica obra de arte ou uma paisagem '+
            'maravilhosa, não sinto vontade de apreciá-las sozinho e até fico melindrado; nasce em '+
            'mim o desejo de mostrá-las a um grande número de pessoas, para alegrá-las. Dessa forma, '+
            'minha maior satisfação é alegrar o próximo, o que me faz ficar alegre também.',
    livro: 'Por Meishu-Sama - 21 de abril de 1954',
    paginas: ''
},
{
  id: 3,
  titulo: 'Bom Senso',
  resumo: 'A verdadeira fé deve ter como foco não ferir o bom senso no falar e no agir. (...) , '+
          'quanto mais a pessoa se empenha na fé, mais deve se portar como uma pessoa comum. '+
          'Isto ocorre porque ela assimilou completamente a fé. A pessoa deve chegar a tal ponto que, '+
          'sem dar indícios da fé que professa, causa boa impressão a todos, e seus atos e palavras '+
          'sempre se baseiam no bom senso.',
  texto:  'A verdadeira fé deve ter como foco não ferir o bom senso no falar e no agir. '+
          'É preciso cautela com relação a fé baseada em ações excêntricas, discursos '+
          'estranhos e possessões por divindades, muito comuns na sociedade. Entretanto, '+
          'muitas pessoas tendem a dar mais crédito e a se sentir gratas a esses tipos de fé '+
          'por não possuírem conhecimento espiritual. Isso é até compreensível, mas é preciso '+
          'cuidado. Também é reprovável a fé que se julga superior às outras, que não se relaciona '+
          'com pessoas de outros grupos religiosos. '+
          'A verdadeira fé é aquela que crê que a missão da religião é salvar a humanidade '+
          'e evita agir de forma exclusivista, sem se restringir ao próprio grupo. Basta observar '+
          'o caso do Japão antes do fim da Segunda Guerra Mundial para entender o que quero dizer: '+
          'sofreu amarga derrota por ter visado apenas ao próprio bem e ficado indiferente à sorte '+
          'dos demais países. '+
          'Acredito que o propósito final da fé é a formação de seres humanos perfeitos. '+
          'Evidentemente, não se pode esperar a perfeição do mundo, mas o aperfeiçoamento para '+
          'consegui-la passo a passo é a verdadeira atitude de fé. Portanto, quanto mais a pessoa se '+
          'empenha na fé, mais deve se portar como uma pessoa comum. Isto ocorre porque ela assimilou '+
          'completamente a fé. A pessoa deve chegar a tal ponto que, sem dar indícios da fé que professa, '+
          'causa boa impressão a todos, e seus atos e palavras sempre se baseiam no bom senso. No seu contato '+
          'com os outros, assemelha-se à suave brisa da primavera. É modesta, gentil, e deseja crescente '+
          'felicidade do próximo e o bem-estar da comunidade. '+
          'Sempre afirmo: para ser feliz, é preciso fazer o próximo feliz, pois a Divina recompensa '+
          'que disto provem, é a verdadeira felicidade. Portanto, devem saber que buscar a '+
          'própria felicidade com o sacrifício alheio significa provocar nada mais que um efeito contrário.',
  livro: 'Por Meishu-Sama - 5 de setembro de 1948',
  paginas: ''
},
{
  id: 4,
  titulo: 'Treino de Humildade',
  resumo: 'Na vida, o treino de humildade é importante, constituindo uma prática '+
          'tradicional entre os religiosos. (...) '+
          'Orgulho, mania de grandeza, pedantismo e vaidade produzem efeitos negativos. O ponto fraco do ser '+
          'humano é gostar de se exibir, tão logo comece a se elevar socialmente. (...) '+
          'Deus desaprova a presunção. Empurrar as pessoas nas conduções, no meio da '+
          'multidão, enfim, em qualquer lugar, para obter situação privilegiada, é falta de '+
          'humildade, é uma atitude desprezível, que revela feio egoísmo. (...)',
  texto:  'Na vida, o treino de humildade é importante, constituindo uma prática tradicional '+
          'entre os religiosos. Observamos, entretanto, que falta humildade a muitos pregadores. '+
          'Os velhos axiomas “O falcão inteligente oculta as garras” e “Quanto mais carregada de '+
          'grãos, mais se curva a espiga de arroz” referem-se à humildade. '+
          'Orgulho, mania de grandeza, pedantismo e vaidade produzem efeitos negativos. '+
          'O ponto fraco do ser humano é gostar de se exibir, tão logo comece a se elevar '+
          'socialmente. Por exemplo, quando um homem que exerce uma profissão comum passa a '+
          'ser respeitado dentro da vida religiosa, recebendo uma função de destaque, sendo '+
          'chamado de “professor”, “ministro” etc., poderá indagar a si próprio: “Será que '+
          'sou tão importante?” De início, ele se sentirá emocionado, feliz, agradecido. '+
          'Com o tempo, no entanto, terá ânsia de ver reconhecida sua importância. '+
          'Até então tudo ia bem, mas, com esse novo pensamento, a pessoa começará a se '+
          'tornar impertinente e desagradável, embora não tome consciência do que lhe ocorre. '+
          'Deus desaprova a presunção. Empurrar as pessoas nas conduções, no meio da multidão, '+
          'enfim, em qualquer lugar, para obter situação privilegiada, é falta de humildade, é '+
          'uma atitude desprezível, que revela feio egoísmo. '+
          'Formar uma sociedade harmoniosa e agradável foi, em todas as épocas, ideal da verdadeira '+
          'democracia.',
  livro: 'Por Meishu-Sama - 25 de janeiro de 1949',
  paginas: ''
},
{
  id: 5,
  titulo: 'Fé e Confiança',
  resumo: 'É necessário distinguir claramente como age um autêntico homem de fé. '+
          'Teoricamente é simples: que inspire confiança nos que convivem com ele; '+
          'que todos confiem nas suas palavras; que, no contacto com as pessoas, elas '+
          'sintam que só lhes advirá o bem, porque ele é uma pessoa excelente. (...) '+
          'A confiança é realmente um tesouro. Quem a merece jamais passará por dificuldades '+
          'monetárias, pois todos sentirão prazer em lhe fazer empréstimos. Estou me referindo '+
          'à confiança entre os homens; mas obter a confiança de Deus é algo de valor inestimável. '+
          'Se a conseguirmos, tudo correrá bem e teremos uma vida repleta de alegrias.',
  texto:  'Existem muitas pessoas que seguem uma religião, mas o homem de verdadeira fé é raro. '+
          'O facto de alguém se considerar um verdadeiro religioso, nada significa, porque o '+
          'julgamento está baseado num critério subjetivo. Só é de facto um verdadeiro religioso '+
          'aquele que assim for reconhecido objetivamente. '+
          'É necessário distinguir claramente como age um autêntico homem de fé. Teoricamente é '+
          'simples: que inspire confiança nos que convivem com ele; que todos confiem nas suas palavras; '+
          'que, no contacto com as pessoas, elas sintam que só lhes advirá o bem, porque ele é uma '+
          'pessoa excelente. '+
          'Obter tal confiança não é difícil. O essencial é não mentir e favorecer primeiramente '+
          'o próximo, deixando os interesses pessoais relegados para segundo plano. As pessoas '+
          'devem comentar a respeito desse homem: “É alguém que me ajudou, que me salvou... '+
          'É pessoa muito bondosa... Seria um grande prazer tê-lo como amigo. É uma criatura '+
          'muito agradável...” Tal indivíduo certamente terá o respeito e a estima de todos, o '+
          'que é muito compreensível. Nós mesmos, se encontrássemos uma pessoa assim, desejaríamos '+
          'cultivar sua amizade, confiar-lhe nossos problemas e nos sentiríamos ligados a ela. '+
          'Essa dedicação, entretanto, não pode ter caráter passageiro. Exemplifiquemos com o arroz: '+
          'quem se habitua com ele, a cada dia o acha mais saboroso. O homem de verdadeira fé pode ser '+
          'comparado ao arroz. '+
          'No mundo, predominam pessoas que contrariam tudo o que acabamos de dizer: suas ações '+
          'comprometedoras levam-nas a perder a confiança do próximo, sem que isto as preocupe. '+
          'Mentem de tal forma, que podem ser desmascaradas a qualquer momento. Embora possuam boas '+
          'qualidades, suscitam desconfiança e  desvalorizam-se aos olhos dos outros. '+
          'Mentir é uma grande tolice; basta uma pequena mentira para se ficar desacreditado. '+
          'Se investigarmos por que certas pessoas não melhoram de situação, embora sejam esforçadas '+
          'e assíduas no trabalho, veremos que elas não merecem crédito, devido às suas mentiras. '+
          'A confiança é realmente um tesouro. Quem a merece jamais passará por dificuldades '+
          'monetárias, pois todos sentirão prazer em lhe fazer empréstimos. Estou me referindo à '+
          'confiança entre os homens; mas obter a confiança de Deus é algo de valor inestimável. '+
          'Se a conseguirmos, tudo correrá bem e teremos uma vida repleta de alegrias.',
  livro: 'Por Meishu-Sama - 18 de junho de 1949',
  paginas: ''
},
{
  id: 6,
  titulo: 'Sinceridade',
  resumo: 'Só a sinceridade é capaz de resolver os problemas dos indivíduos, do país e do mundo. (...) '+
          'Homens, a chave de todos os problemas está na sinceridade.',
  texto:  'Só a sinceridade é capaz de resolver os problemas dos indivíduos, do país e do mundo. '+
          'A deficiência política resulta da falta de sinceridade. A pobreza material e a '+
          'corrupção moral também têm a mesma origem. Enfim, todos os problemas são gerados '+
          'pela falta de sinceridade. Religião, educação e arte que não se alicerçam na sinceridade, '+
          'passam a representar meras formas sem conteúdo. '+
          'Homens, a chave de todos os problemas está na sinceridade.',
  livro: 'Por Meishu-Sama - 25 de janeiro de 1949',
  paginas: ''
},
{
  id: 7,
  titulo: 'Não se Irrite',
  resumo: '(...) Quem tem missão importante, é submetido por Deus a muitos aprimoramentos. '+
          'Creio que ter de reprimir a raiva, é uma das maiores provas. Aqueles que têm '+
          'muitas razões para irritar-se, devem compreender que sua missão é grandiosa. Se '+
          'conseguirem resistir a todo tipo de provocação, mantendo calma absoluta, terão '+
          'concluído uma etapa do seu aprimoramento. (...)',
  texto:  'Diz um velho ditado: “Tolerar o que é fácil está ao alcance de todos, mas a '+
          'verdadeira tolerância significa tolerar o que é intolerável”. Outro ditado '+
          'aconselha: “Carrega sempre contigo o saco da paciência e costura-o toda vez que ele '+
          'se romper”. Encontro boas razões nesses conselhos. '+
          'As pessoas me perguntam: “Que práticas ascéticas o senhor realizou? Subiu alguma '+
          'montanha para banhar-se numa cachoeira, jejuou ou fez outras penitências?” Então esclareço '+
          'que jamais pratiquei tais coisas. Todas as minhas “penitências” consistiram em tolerar a '+
          'tortura das dívidas e reprimir a ira. Quem ouve, fica espantado, mas é a pura verdade. '+
          'Creio que Deus determinou aperfeiçoar-me mediante purificações desse tipo, pois continuamente '+
          'têm aparecido fortes motivos para eu ficar irritado. Por natureza, detesto irritar-me, mas '+
          'há sempre alguma coisa que me afeta nesse sentido. '+
          'Certa vez, passei por tanta vergonha devido a um desentendimento, que mal conseguia '+
          'encarar as pessoas. Minha indignação atingia o auge e eu não conseguia reprimi-la. '+
          'Foi quando me fizeram um convite para comparecer a uma festa. Nas circunstâncias, o '+
          'convite era irrecusável. Lá, entretanto, permaneci desligado, sem poder concentrar '+
          'meu espírito. Tomei até uma dose de saquê, para me descontrair. Isso demonstra como eu '+
          'estava perturbado. Somente após alguns dias consegui recobrar a tranquilidade. '+
          'Mais tarde, vim a saber que a minha ida àquela festa salvou-me de uma grande desgraça. '+
          'Se não fosse a indignação daquele momento, eu não teria comparecido a ela, e teria recebido '+
          'um golpe fatal. Realmente fui salvo pela ira e não pude conter minha gratidão. '+
          'Quem tem missão importante, é submetido por Deus a muitos aprimoramentos. Creio que '+
          'ter de reprimir a raiva, é uma das maiores provas. Aqueles que têm muitas razões para '+
          'irritar-se, devem compreender que sua missão é grandiosa. Se conseguirem resistir a todo '+
          'tipo de provocação, mantendo calma absoluta, terão concluído uma etapa do seu aprimoramento. '+
          'Há um episódio interessante que eu gostaria de relatar. '+
          'Na Era Meiji, houve um homem famoso pela sua paciência, o Sr. Buei Nakano, presidente do '+
          'Conselho Privado de Comércio. Uma vez lhe perguntaram qual era o segredo de seu espírito '+
          'de tolerância. Ele respondeu: “Por natureza, eu era irascível. Mas, certo dia, ao visitar '+
          'o grande industrial Eiichi Shibuzawa, ouvi-o discutindo com a esposa no cômodo contíguo '+
          'àquele em que eu estava. Informado de minha presença, ele abriu a porta corrediça e veio '+
          'sentar-se junto a mim. Trazia a fisionomia serena de sempre; nem parecia vir de uma '+
          'discussão. Admirei-me e, ao mesmo tempo, tive a revelação de algo importante: o poder de '+
          'controlar a ira. Compreendi que aquele era o segredo de seu prestígio no mundo industrial, '+
          'e que eu devia seguir seu exemplo e esforçar-me para reprimir a cólera com facilidade. '+
          'Desde então passei a disciplinar-me nesse sentido, e tudo começou a correr normalmente em '+
          'minha vida, até eu atingir a condição atual.” '+
          'Lembrem-se, pois, de que Deus treina e disciplina aqueles que têm uma grande missão a cumprir. '+
          'Gostaria de voltar ao assunto das dívidas. '+
          'Baseado na minha própria experiência, concluí que as dívidas são motivadas pela precipitação, '+
          'que nos faz forçar situações. '+
          'Jamais devemos forçar a situação. Se o fizermos, talvez obtenhamos um êxito passageiro; '+
          'entretanto, mais cedo ou mais tarde, seremos colhidos pelas consequências, '+
          'enfrentando obstáculos inesperados. É possível que, após um rápido sucesso, '+
          'nos vejamos forçados a voltar ao ponto de partida. Examinando as causas da derrota '+
          'do Japão na Segunda Guerra Mundial, veremos que houve quem forçasse muito a situação. '+
          'Impor soluções e precipitar providências provoca desequilíbrio mental e impede as '+
          'boas inspirações. Pior ainda é agir à força, de qualquer maneira, na falta de ideias. '+
          'Só devemos tomar resoluções depois que surge a ideia apropriada, isto é, quando houver '+
          'certeza de que o plano concebido não vai falhar. Em outras palavras, é preciso aplicar o '+
          'método: “Pense duas vezes antes de agir”. '+
          'Quase sempre é muito difícil o pagamento das dívidas. Se elas se prolongarem, os '+
          'juros aumentarão, causando grande sofrimento moral. '+
          'Existem dívidas ativas e dívidas passivas. As ativas, fazemo-las para investir em '+
          'negócio rendoso; as passivas, para cobrir prejuízos. Embora muitas vezes estas '+
          'últimas sejam inevitáveis, não devemos contraí-las. Se formos vítimas de prejuízos, '+
          'devemos abandonar toda ostentação e falsa aparência, reduzir nossos gastos e esperar que '+
          'surjam novas oportunidades. '+
          'Também desejo chamar a atenção para um ponto importante: a ganância. Consideremos o '+
          'velho ditado: “Quem tudo quer, tudo perde”. Os prejuízos geralmente são causados pela '+
          'ambição de ganhar demais. Não existe, neste mundo, o pretenso “negócio-da-china” que '+
          'tantos vivem a propor. Desconfiemos desse tipo de negócio. O empreendimento que não '+
          'parece grande, oferece melhores perspectivas. Exemplificarei com minha própria experiência. '+
          'Certa ocasião, eu precisava de dinheiro para saldar dívidas e impulsionar a obra religiosa. '+
          'Não foi fácil consegui-lo; enquanto eu o desejava muito, não entrava dinheiro algum. '+
          'Por fim, resignei-me, deixando o problema nas mãos de Deus. Quando eu já estava esquecido '+
          'de tudo, comecei a receber inesperadamente grandes somas. Percebi, então, que o mundo '+
          'não pode ser explicado em termos de raciocínio comum.',
  livro: 'Por Meishu-Sama - 25 de janeiro de 1949',
  paginas: ''
},
{
  id: 8,
  titulo: 'Ceda para Conquistar',
  resumo: '“Seja flexível para conquistar” é uma regra de ouro. (...)'+
          'Tentar impor nossas opiniões é uma psicologia inábil. Ainda que estejamos '+
          'certos, não devemos desnecessariamente insistir em argumentos a nosso favor. '+
          'Aprendendo a ceder em determinadas circunstâncias, acabaremos vencendo, '+
          'porque nos ativemos ao que é justo e verdadeiro. (...)',
  texto:  '“Seja flexível para conquistar” é uma regra de ouro. Pode ser difícil '+
          'praticá-la, mas devemos treinar a nossa índole e educar a nossa mente nesse '+
          'sentido. Em alguns casos, é preferível aparentar ignorância ou mesmo perder '+
          'uma discussão. Qualquer possível humilhação ficará gravada apenas na mente '+
          'e por um período temporário. Com o passar do tempo, a outra pessoa pode começar '+
          'a compreender a verdadeira situação e mudar de atitude. Pode pensar: “Eis uma pessoa '+
          'sincera”, começar acreditar em você e até mesmo a admirá-lo. Tendo aparentemente '+
          'vencido uma discussão, seu adversário se torna inseguro por não fazer ideia do '+
          'que você tem em mente. Assim, o derrotado se torna vencedor e é por isso que, às '+
          'vezes, é preferível deixar que os outros persistam em suas ideias. '+
          'Tentar impor nossas opiniões é uma psicologia inábil. Ainda que estejamos certos, '+
          'não devemos desnecessariamente insistir em argumentos a nosso favor. Aprendendo a '+
          'ceder em determinadas circunstâncias, acabaremos vencendo, porque nos ativemos ao '+
          'que é justo e verdadeiro. '+
          'Algumas vezes, quando as pessoas se dedicam a algo importante, pensam que isso '+
          'requer esforço, resistência e concentração. No entanto, quando opomos resistência, '+
          'nosso poder interno se restringe, ao passo que, quando assumimos uma postura '+
          'descontraída, ele circula livremente. O mesmo princípio se aplica ao Johrei. '+
          'Quanto mais relaxada mantivermos a mente e as mãos, mais sintonizados estaremos '+
          'e mais eficiente será o Johrei. '+
          'Os grandes generais sabem como e quando retirar-se de uma batalha. '+
          'Permanecer correndo risco desnecessário é uma tática errônea e não '+
          'constitui verdadeira coragem. O que importa é o resultado final. '+
          'Muitas pessoas obtêm resultados contrários à sua expectativa, porque o '+
          'caminho do mundo é geralmente muito diferente do caminho da verdade. '+
          'Quando iniciei a Igreja Messiânica Mundial, costumava dizer aos membros que '+
          'trabalhassem tão discreta e silenciosamente quanto possível. Alguns sugeriram '+
          'que se fizesse propaganda na imprensa, mas nunca fiz. Muitas pessoas tendem a '+
          'atingir os seus objetivos empregando os efêmeros sistemas materiais, assim como '+
          'procuram nos remédios, alívio temporário para as enfermidades. Num trabalho '+
          'verdadeiramente espiritual, devemos aspirar ao verdadeiro e eterno, e não '+
          'ao falso e transitório.',
  livro: 'Por Meishu-Sama - Extraído do livro “Os novos Tempos”',
  paginas: ''
},
{
  id: 9,
  titulo: 'Domine o Gá',
  resumo: 'Na vida cotidiana do homem, não há coisa mais temível do que o “ga” '+
          '(eu, ego). Isso pode ser bem compreendido se atentarmos para o fato de que, '+
          'no Mundo Espiritual, a eliminação do “ga” é considerada o aprimoramento fundamental.',
  texto:  'Na vida cotidiana do homem, não há coisa mais temível do que o “ga” (eu, ego). '+
          'Isso pode ser bem compreendido se atentarmos para o fato de que, no Mundo Espiritual, '+
          'a eliminação do “ga” é considerada o aprimoramento fundamental. '+
          'Quando eu era da Igreja Omoto (2), encontrei, no “Ofudessaki” (3), as seguintes '+
          'frases: “Não há coisa mais temível do que o ‘ga’; até divindades cometeram erros '+
          'por causa dele.” E também: “Devem ter ‘ga’ e não devem ter ‘ga’; é bom que o '+
          'tenham, mas não o manifestam.” Fiquei profundamente impressionado, pela perfeita '+
          'explicação da verdadeira natureza do “ga” em frases tão simples. É escusado dizer '+
          'que elas me induziram a uma profunda reflexão. '+
          'Havia, ainda, esta frase: “Em primeiro lugar, a docilidade.” Achei-a extraordinária. '+
          'Isto porque, até hoje, para aqueles que seguiram docilmente os meus conselhos, '+
          'tudo correu bem, sem fracassos. Há pessoas que não são bem sucedidas por terem '+
          'um “ga” muito forte. É realmente penoso ver os constantes fracassos decorrentes '+
          'do “ga”. '+
          'Como foi exposto, o princípio da fé é não manifestar o “ga”, ser dócil e '+
          'não mentir.',
  livro: 'Por Meishu-Sama - 18 de fevereiro de 1950',
  paginas: ''
},{
  id: 10,
  titulo: 'O Homem Depende do seu Pensamento',
  resumo: 'É realmente verdade que gratidão gera gratidão e lamúria gera lamúria. '+
          'Isto acontece porque o coração agradecido comunica-se com Deus (...) '+
          'A frase “Alegrem-se que virão coisas alegres”, expressa uma grande verdade.',
  texto:  'É realmente verdade que gratidão gera gratidão e lamúria gera lamúria. '+
          'Isto acontece porque o coração agradecido comunica-se com Deus, e o queixoso '+
          'relaciona-se com Satanás. Assim, quem vive agradecendo, torna-se feliz; quem '+
          'vive se lamuriando, caminha para a infelicidade. '+
          'A frase “Alegrem-se que virão coisas alegres”, expressa uma grande verdade.',
  livro: 'Por Meishu-Sama - 3 de setembro de 1949',
  paginas: ''
}
]

  constructor() { }

  public getEnsinamento(): any {
    const ensinamentoObservable = new Observable(observer => {
           setTimeout(() => {
               observer.next(this.ensinamento);
           }, 1000);
    });

    return ensinamentoObservable 
  }

}
