import { Component, OnInit } from '@angular/core';

import { Usuario } from './usuario';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  private usuario: Usuario = new Usuario();
  private imagePrincipal: string = 'assets/img/fotos/GUIAS_02.jpg';

  getImage(){
    return this.imagePrincipal;
  }

  fazerLogin(){
    this.authService.login(this.usuario);
  }
  constructor(private authService: AuthService) { }

  ngOnInit() {
  }

}
