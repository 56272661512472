import { Component, TemplateRef, OnInit} from '@angular/core';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Ensinamento } from './ensinamento.model';
import { EnsinamentosDetalhesService } from './ensinamentos-detalhes.service';

@Component({
  selector: 'app-ensinamentos-detalhes',
  templateUrl: './ensinamentos-detalhes.component.html',
  styleUrls: ['./ensinamentos-detalhes.component.css']
})
//export class EnsinamentosDetalhesComponent implements OnInit {
export class EnsinamentosDetalhesComponent {
  modalRef: BsModalRef;
  
  ensinamentos : Ensinamento[] = [];

  constructor(private modalService: BsModalService, private ensinamentoService:EnsinamentosDetalhesService) {
  
   }

   openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  
  //ngOnInit() {
  //}

  ngOnInit() {    
    const ensinamentoObservable = this.ensinamentoService.getEnsinamento();
      ensinamentoObservable.subscribe((ensinamentoData: Ensinamento[]) => {
        this.ensinamentos = ensinamentoData;
      });
  }

}
