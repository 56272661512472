import { Component, OnInit } from '@angular/core';
import { AuthService } from '../login/auth.service';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-barra-navegacao',
  templateUrl: './barra-navegacao.component.html',
  styleUrls: ['./barra-navegacao.component.css']
})
export class BarraNavegacaoComponent implements OnInit {

  isLoggedIn$: Observable<boolean>; 

  isCollapsed: boolean = true;
    html: string = `
      <table>
        <tr>
          <td>
            <a href="">
              <img src="teste"/>
              <br>
              Teste 1
            </a>
          </td>
        <td>
          Teste 2
        </td>

        </tr>
      
      </table>
      `;

  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.isLoggedIn$ = this.authService.isLoggedIn;
  }

  onLogout(){
    this.authService.logout();                  
  }

}
