import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-relacao-dedicantes-chamada',
  templateUrl: './relacao-dedicantes-chamada.component.html',
  styleUrls: ['./relacao-dedicantes-chamada.component.css']
})
export class RelacaoDedicantesChamadaComponent implements OnInit {

  private imagemChamada: string = 'assets/img/pessoas/giovana_lindote_01.jpg';
  private tituloChamada: string ='Relação de Dedicantes';
  private textoChamada: string =  'Caros Responsáveis, informe ao SSG a relação ' + 
                                  'de guias que dedicarão, através do botão abaixo.';
  
    getImagemChamada(){
      return this.imagemChamada;
    }
  
   getTituloChamada(){
      return this.tituloChamada;
    }
  
    getTextoChamada(){
      return this.textoChamada;
    }

  constructor() { }

  ngOnInit() {
  }

}
