import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ssg-evolucao-chamada',
  templateUrl: './ssg-evolucao-chamada.component.html',
  styleUrls: ['./ssg-evolucao-chamada.component.css']
})
export class SsgEvolucaoChamadaComponent implements OnInit {

  private imagemChamada: string = 'assets/img/pessoas/ministra_vera_01.jpg';
  private tituloChamada: string ='Histórico SSG';
  private textoChamada: string =  'Você sabe como o Solo Sagrado começou? Clique abaixo e conheça'; 
  
    getImagemChamada(){
      return this.imagemChamada;
    }
  
   getTituloChamada(){
      return this.tituloChamada;
    }
  
    getTextoChamada(){
      return this.textoChamada;
    }

  constructor() { }

  ngOnInit() {
  }

}
